import React, { useEffect, useState } from "react";
import { Contract, BrowserProvider, formatUnits } from "ethers";
import { Card, Button, Spinner, Alert, Table, Collapse } from "react-bootstrap";
import TEAM_VESTING_ABI from "../abis/TeamVestingABI.json";
import SETTINGS from "../SETTINGS";

const UserVesting = ({ provider, address }) => {
  const [vestingInfo, setVestingInfo] = useState(null);
  const [releaseSchedule, setReleaseSchedule] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [releasing, setReleasing] = useState(false);
  const [open, setOpen] = useState(false); // State to manage collapse

  const vestingContractAddress = SETTINGS.teamVestingContract; // Replace with your actual contract address

  useEffect(() => {
    const fetchVestingInfo = async () => {
      try {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const vestingContract = new Contract(vestingContractAddress, TEAM_VESTING_ABI, signer);

        // Fetch beneficiary information
        const beneficiary = await vestingContract.beneficiaries(signer.address);

        if (beneficiary.totalAmount.toString() === "0") {
          setVestingInfo(null); // No vesting info for this address
          setError("You are not a beneficiary in the vesting contract.");
        } else {
          const totalAmount = formatUnits(beneficiary.totalAmount, 18).toString();
          const releasedAmount = formatUnits(beneficiary.releasedAmount, 18).toString();
          const releasableAmount = formatUnits(await vestingContract.releasableAmount(signer.address), 18).toString();

          // Fetch release times and percentages
          const releaseTimes = await vestingContract.getReleaseTimes();
          const releasePercentages = await vestingContract.getReleasePercentages();

          // Convert releaseTimes to readable format
          const releaseSchedule = releaseTimes.map((time, index) => ({
            date: new Date(parseInt(time.toString()) * 1000).toLocaleDateString(),
            percentage: releasePercentages[index].toString(),
          }));

          setVestingInfo({
            totalAmount,
            releasedAmount,
            releasableAmount,
          });

          setReleaseSchedule(releaseSchedule);
        }
      } catch (error) {
        console.error("Error fetching vesting info:", error);
        setError("Failed to load vesting information. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchVestingInfo();
  }, [address, provider]);

  const handleReleaseTokens = async () => {
    if (!provider) {
      setError("No provider available.");
      return;
    }

    try {
      setReleasing(true);
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const vestingContract = new Contract(vestingContractAddress, TEAM_VESTING_ABI, signer);

      const tx = await vestingContract.releaseTokens();
      await tx.wait();
      alert("Tokens released successfully!");
    } catch (error) {
      console.error("Error releasing tokens:", error);
      alert("Failed to release tokens. Please try again.");
    } finally {
      setReleasing(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status" />
        <p>Loading vesting information...</p>
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!vestingInfo) {
    return null; // Don't show the component if the user is not a beneficiary
  }

  return (
    <Card className="pokemon-card mb-3">
      <Card.Body>
        

        <Button
          onClick={() => setOpen(!open)}
          aria-controls="vesting-collapse-text"
          aria-expanded={open}
          className="mb-3 buton"
        >
          {open ? "Hide Vesting Information" : "Show Vesting Information"}
        </Button>

        <Collapse in={open}>
          <div id="vesting-collapse-text">
            <div className="vesting-details">
              <p>
                <strong>Total Allocated:</strong> {vestingInfo.totalAmount.toLocaleString()} tokens
              </p>
              <p>
                <strong>Released:</strong> {vestingInfo.releasedAmount.toLocaleString()} tokens
              </p>
              <p>
                <strong>Available for Release:</strong> {vestingInfo.releasableAmount.toLocaleString()} tokens
              </p>
            </div>

            <h5>Release Schedule</h5>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Percentage Unlocked</th>
                </tr>
              </thead>
              <tbody>
                {releaseSchedule.map((release, index) => (
                  <tr key={index}>
                    <td>{release.date}</td>
                    <td>{release.percentage}%</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button
          variant="primary"
          className="buton"
          onClick={handleReleaseTokens}
          disabled={releasing || vestingInfo.releasableAmount === "0"}
        >
          {releasing ? <Spinner as="span" animation="border" size="sm" /> : "Release Tokens"}
        </Button>
          </div>
        </Collapse>

       
      </Card.Body>
     
    </Card>
  );
};

export default UserVesting;
