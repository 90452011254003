import React, { useState, useEffect } from 'react';
import { Container, Spinner, Table, Pagination, Form } from 'react-bootstrap';
import { BrowserProvider, Contract, ethers, formatEther } from 'ethers';
import SETTINGS from '../SETTINGS';
import StakingABI from '../abis/StakingABI.json'; // Import Staking ABI

function AdminStake({ provider, address }) {
  const [loading, setLoading] = useState(false);
  const [stakers, setStakers] = useState([]); // State to store stakers
  const [allStakes, setAllStakes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // For pagination
  const [rowsPerPage] = useState(20); // Number of rows per page
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' }); // Sorting state

  const fetchStakes = async () => {
    setLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const stakingContract = new Contract(SETTINGS.stakingContract, StakingABI, signer);

    const stakers = [];
    let index = 0;

    try {
      // Loop until fetching stakers fails or returns an invalid result
      while (true) {
        try {
          const staker = await stakingContract.stakers(index);
          if (!staker) break; // If the staker is null or undefined, break the loop
          stakers.push(staker);
          index++;
        } catch (error) {
          // Assume we reached the end of the stakers array when an error occurs
          console.log(`Reached the end of stakers at index ${index}`);
          break;
        }
      }

      // Fetch all stakes for each staker and flatten the results
      const stakes = (await Promise.all(
        stakers.map(async (address) => {
          const userStakes = await stakingContract.getUserStakes(address);

          return userStakes.map(stake => {
            const amount = parseFloat(formatEther(stake.amount.toString())).toLocaleString();
            const timestamp = new Date(parseFloat(stake.timestamp.toString()) * 1000);
            const duration = parseFloat(stake.duration.toString()) / (24 * 60 * 60); // Convert to days
            const endTime = new Date(timestamp.getTime() + parseFloat(stake.duration.toString()) * 1000); // Calculate end time
            const remainingDays = Math.max(Math.floor((endTime - new Date()) / (1000 * 60 * 60 * 24)), 0); // Calculate remaining days

            return {
              amount,
              timestamp: timestamp.toLocaleString(),
              duration,
              remainingDays,
              claimed: stake.claimed,
              staker: address // Include the staker's address in each stake
            };
          });
        })
      )).flat(); // Flatten the array to remove the grouping by address

      setAllStakes(stakes);
    } catch (error) {
      console.error('Error fetching stakers and stakes:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStakes();
  }, []);

  // Sorting logic
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedStakes = [...allStakes].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setAllStakes(sortedStakes);
  };

  // Pagination and search logic
  const filteredStakes = allStakes.filter(stake =>
    stake.staker.toLowerCase().includes(searchQuery.toLowerCase()) ||
    stake.amount.toString().includes(searchQuery)
  );

  const indexOfLastStake = currentPage * rowsPerPage;
  const indexOfFirstStake = indexOfLastStake - rowsPerPage;
  const currentStakes = filteredStakes.slice(indexOfFirstStake, indexOfLastStake);

  const totalPages = Math.ceil(filteredStakes.length / rowsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  // Total amount and number of stakes
  const totalAmount = allStakes.reduce((acc, stake) => acc + parseFloat(stake.amount), 0).toLocaleString();
  const totalStakes = allStakes.length;

  // Generate pagination items
  const paginationItems = [];
  for (let number = 1; number <= totalPages; number++) {
    paginationItems.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => handleClick(number)}>
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Container className="pokemon-card mt-4">
      <h2>Admin Stakes</h2>
      <hr />

      {/* Total Stake Amount and Number of Stakes */}
      <div className="mb-3">
        <strong>Total Staked Amount: {totalAmount} Tokens</strong>
        <br />
        <strong>Number of Stakes: {totalStakes}</strong>
      </div>

      {/* Search Input */}
      <Form.Control
        type="text"
        placeholder="Search by address or amount"
        value={searchQuery}
        onChange={handleSearch}
        className="mb-3"
      />

      {loading ? (
        <center>
          <br />
          <Spinner animation="border" role="status" />
          <br />
          <span className="sr-only">Loading ...</span>
          <br />
        </center>
      ) : (
        <div>
          <h2>All Stakes</h2>
          {filteredStakes.length > 0 ? (
            <>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th onClick={() => handleSort('staker')} style={{ cursor: 'pointer' }}>Address</th>
                    <th onClick={() => handleSort('amount')} style={{ cursor: 'pointer' }}>Amount Staked</th>
                    <th onClick={() => handleSort('timestamp')} style={{ cursor: 'pointer' }}>Start Time</th>
                    <th onClick={() => handleSort('duration')} style={{ cursor: 'pointer' }}>Duration (Days)</th>
                    <th onClick={() => handleSort('remainingDays')} style={{ cursor: 'pointer' }}>Remaining Days</th>
                    <th onClick={() => handleSort('claimed')} style={{ cursor: 'pointer' }}>Claimed</th>
                  </tr>
                </thead>
                <tbody>
                  {currentStakes.map((stake, index) => (
                    <tr key={indexOfFirstStake + index}>
                      <td>{indexOfFirstStake + index + 1}</td>
                      <td><small>{stake.staker}</small></td>
                      <td>{stake.amount}</td>
                      <td>{stake.timestamp}</td>
                      <td>{stake.duration}</td>
                      <td>{stake.remainingDays}</td>
                      <td>{stake.claimed ? 'Yes' : 'No'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination>{paginationItems}</Pagination>
            </>
          ) : (
            <p>No stakes found.</p>
          )}
        </div>
      )}
    </Container>
  );
}

export default AdminStake;
