import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
// 1. Get projectId
const projectId = '17d187135c9b3557cdb2e14fe023edf1'

const mainnet = {
  chainId: 137,
  name: 'Polygon',
  currency: 'MATIC',
  explorerUrl: 'https://polygonscan.com',
  rpcUrl: 'https://polygon-mainnet.infura.io/v3/9f1e5354e01044d18b20e08585da8330'
}
// 2. Set chains
const testnet = {
  chainId: 43114,
  name: 'Avalanche',
  currency: 'AVAX',
  explorerUrl: 'https://avascan.info',
  rpcUrl: 'https://api.avax.network/ext/bc/C/rpc'
}

// 3. Create a metadata object
const metadata1 = {
  name: 'Qvrse',
  description: 'Qvrse description',
  url: 'https://testtoken.qvrse.io/', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}
const metadata = {
  name: 'Spacem',
  description: 'Spacem',
  url: 'https://testtoken.qvrse.io/', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}


// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    //enableEmail: true,
    enableEIP6963: true,
  }),
  chains: [testnet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
