import React, { useEffect, useState } from "react";

import Logo from "../logo.png"; // ABI of your contract
import logoToken from "../logoToken.png"; // ABI of your contract
import addImg from "../add.png"; // ABI of your contract
import { useContract } from "../hooks/useContract";
import { ERC20_ABI } from "../abis/erc20";
import QtechStakingABI from "../abis/QtechStakingABI.json";
import NodesABI from "../abis/NodesABI.json";
import CollectionABI from "../abis/CollectionABI.json";
import SETTINGS from "../SETTINGS";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Alert,
  Card,
  ToggleButtonGroup,
  ToggleButton,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, formatUnits } from "ethers";
import Node from "../components/Node";
import UserVesting from "../components/UserVesting"; 
import EtherscanTransactions from "../components/EtherscanTransactions";
import { useNavigate } from "react-router-dom";
import copyImg from "../copy.png";
import boxImg from "../images/box.png";

function Dashboard({ provider, address, isConn }) {
  const { collections, ownedNFTs, fetchOwnedNFTs, totalRewards } =
    useContract(provider);

  const [usdtBalance, setUsdtBalance] = useState("0");
  const [refCode, setRefCode] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);
  const [refLoading, setRefLoading] = useState(false);
  const [qvrsStakes, setQvrsStakes] = useState([]);
  const [refArr, setRefArr] = useState([]);
  const trunAddress = `${SETTINGS.collection.slice(
    0,
    4
  )}...${SETTINGS.collection.slice(-1 * 4)}`;

  const [loading, setLoading] = useState(false);
  const [txMessage, setTxMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();


  const handleCopy = () => {
    navigator.clipboard.writeText(refCode)
      .then(() => {
        setSuccess(true);
        // Optionally, reset the success message after a timeout
        setTimeout(() => setSuccess(false), 3000);
      })
      .catch(err => console.error('Failed to copy text:', err));
  };

  async function getBalance() {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    // The Contract object
    try {
      const USDTContract = new Contract(
        SETTINGS.usdtAddress,
        ERC20_ABI,
        signer
      );
      const USDTBalance = await USDTContract.balanceOf(address);
      const USDTDecimals = await USDTContract.decimals();
      setUsdtBalance(formatUnits(USDTBalance, USDTDecimals));

      const TokenContract = new Contract(
        SETTINGS.tokenAddress,
        ERC20_ABI,
        signer
      );
      const TokenBalance = await TokenContract.balanceOf(address);
      const TokenDecimals = await TokenContract.decimals();
      setTokenBalance(parseFloat(formatUnits(TokenBalance, TokenDecimals)));
    } catch (error) {
      console.log(error);
    }
  }
  async function getReferral() {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    
    try {
      // Create contract instances for new and old contracts
      const nodeContract = new Contract(
        SETTINGS.nodeContract,
        NodesABI,
        signer
      );
      
      const nodeContractOld = new Contract(
        SETTINGS.nodeContractOld,
        NodesABI,
        signer
      );
      
      // Try to get referral code from the new contract
      let code = await nodeContract.inviteCodeForAddress(address);
      
      // If the code is missing or invalid, fall back to the old contract
      if (!code) { 
        code = await nodeContractOld.inviteCodeForAddress(address);
      }
      
      // Set the referral code, either from the new or old contract
      setRefCode(code);
  
    } catch (error) {
      console.error("Error fetching referral code:", error);
    }
  }
  

  const fetchTokenData = async () => {
   setRefLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    
    try {
      console.log('Fetching data from contract...');
      const nodeContract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      const collectionContract = new Contract(SETTINGS.collection, CollectionABI, signer);

      // Call the exportData function
      const minted = await collectionContract.minted();
      console.log(minted); 

      let tempArr = [];
      let tempReffAmount = 0;
      let userAddress = address;
      let prevAddr = "";
      for(let i=325;i<minted;i++){
        const refby = await nodeContract.referredBy(i);
      
        if(refby.toLowerCase() == userAddress.toLowerCase()){
          prevAddr = userAddress;
          tempArr.push(i);
        }

        if(refby == "0x0000000000000000000000000000000000000000" && prevAddr.toLowerCase() == userAddress.toLowerCase()){
          tempArr.push(i);
        }
        
      }
      console.log(tempArr);
      setRefArr(tempArr);


    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setRefLoading(false);
    }
   
  };



  const importTokenToMetamask = async () => {
    const tokenAddress = SETTINGS.tokenAddress;
    const tokenSymbol = SETTINGS.tokenSymbol;
    const tokenDecimals = 18;
    const tokenImage = "https://node1.spacemonkeez.io/logoToken.png";

    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
          },
        },
      });
    } catch (error) {
      console.log("Error importing token to Metamask:", error);
    }
  };

  const openBuyToken = () => {
    navigate("/buy-token");
  };

  const openStakeToken = () => {
    navigate("/staking");
  };
  const openBuyNode = () => {
    navigate("/nodes");
  };

  const claimRewards = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();

    try {
      setLoading(true);
      setTxMessage("Claiming rewards...");

      // Connect to the NFT contract and claim rewards
      const nftContract = new Contract(SETTINGS.nodeContract, NodesABI, signer);

      // Call the claimRewards function on the NFT contract
      const buyTx = await nftContract.claimAllRewards();
      await buyTx.wait();

      setTxMessage("Rewards claimed!");
    } catch (error) {
      console.error("Error claiming rewards:", error);
      setTxMessage("Failed to claim rewards. Please try again.");
    } finally {
      await fetchOwnedNFTs();
      setLoading(false);
      setTimeout(() => setTxMessage(""), 5000);
    }
  };

  useEffect(() => {
    getBalance();
    getReferral();
  }, [provider, address]);

  const renderOwnedNFTs = () => {
    return ownedNFTs.map((nft, index) => (
      <Node
        key={index}
        provider={provider}
        account={address}
        collectionAddress={nft.collectionAddress}
        tokenId={nft.tokenId}
        unclaimedRewards={nft.unclaimedRewards}
        name={nft.name}
        imageURL={nft.imageURL}
      />
    ));
  };

  if (loading) {
    return (
        <div className="loaderScreen text-center">
            <br />
            <Spinner animation="border" role="status" className='loaderBig' /><br />

            <p className='loaderMsg'>{txMessage}</p>
        </div>
    );
}

  return (
    <>
      <div className="">
        <div className="buy-token-header"></div>
      </div>

     

      {isConn && SETTINGS.teamAddr.some(tA => tA.toLowerCase() === address.toLowerCase()) && (
                <UserVesting provider={provider} address={address} />
              )}
{isConn && refCode != "" && (
<Card className="pokemon-card mb-3">
      <Card.Body>
      <Card.Title>Refferal</Card.Title>
      <Row className="mb-3">
      <Col className="col-12 col-md-6 mb-3">
      {refArr.length == 0 ?(
        <Button
          
        onClick={fetchTokenData}
        className="buton"
      >
       {refLoading ? <Spinner as="span" animation="border" size="sm" /> : "Load referrall data"}
      </Button>
      ):(
        <>
        <small>Income:</small><br />
        {refArr.length * 50} USDT
        
      
        </>
      )}
        
        
        </Col>
    
      
   
      <Col className="col-12 col-md-6">
      <Button onClick={handleCopy} className="buton">
      {success && "Code copied successfully!"}
      {!success && "Copy code: " + refCode}
      </Button>

      </Col>
      <Col className="col-12 mt-3">
      {refArr.map((token) => (
<a className="balanceDisplay buton smallBtn mb-1" target="_blank" href={"https://snowtrace.io/nft/0xFf7120a0889a301c0A12b92Bf62f2Fb81f9891Cc/"+token+"?chainid=43114"}>{token}</a>
        ))}
      </Col>
      
      </Row>
        
        </Card.Body>
        </Card>
)}
      <div>
        <div className="balanceDisplay pokemon-card ">
          <img src={boxImg} />

          {ownedNFTs.length}
          <Button
            variant="primary"
            onClick={openBuyNode}
            className="buton smallBtn"
          >
            <small style={{ fontSize: "12px" }}>BUY NODE</small>
          </Button>
        </div>
        <div className="balanceDisplay pokemon-card ">
          <img src={logoToken} />

          {tokenBalance.toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
          })}
          <small> {SETTINGS.tokenSymbol} </small>

          <Button
            variant="primary"
            onClick={importTokenToMetamask}
            className="buton smallBtn"
          >
            Import ${SETTINGS.tokenSymbol} to Metamask
          </Button>
          {/* 
               <Button variant="primary" onClick={openStakeToken}  className='buton smallBtn'>
           Stake
       </Button>
    */}
        </div>

        <div className="balanceDisplay pokemon-card ">
          <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/825.png" />

          {usdtBalance}
          <small> USDT </small>
        </div>
      </div>
      <Row className="mb-3">
        <Col>
          <div>
            <hr />

            <div>
              <Row>
                <Col sm={12} md={6} lg={4} xl={3} className="mb-4">
                  <Card className="nft-card h-100 pokemon-card">
                    {/*   <Card.Img variant="top" src={boxImg} /> */}
                    <div className="iframe-container">
                      <iframe
                        src={SETTINGS.modelUrl}
                        title="External Content"
                      />
                    </div>

                    <Card.Body>
                      <Card.Title>
                        <h6 className="pokemon-card-title">
                          {" "}
                          {SETTINGS.nodeName}
                        </h6>
                      </Card.Title>
                      <div className="card-text">
                        <h5
                          className="text-center"
                          style={{ marginBottom: "10px", marginTop: "30px" }}
                        >
                          <small>
                            Total unclaimed:
                            <br />{" "}
                          </small>
                          {totalRewards}
                        </h5>
                        <Button
                        style={{ marginBottom: "10px", marginTop: "10px" }}
                          variant="primary"
                          onClick={claimRewards}
                          className="buton"
                        >
                          Claim
                        </Button>
                        <Row>
                          {/*
        <Col xs={6}>
        <EtherscanTransactions provider={provider} account={address}  />
        </Col>
        */}
                          <Col xs={6}>
                            <Button
                              variant="primary"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  SETTINGS.collection
                                );
                              }}
                              className="buton smallBtn"
                            >
                              <small style={{ fontSize: "12px" }}>
                                {trunAddress}
                              </small>{" "}
                              <img
                                src={copyImg}
                                style={{ width: "13px", marginLeft: "5px" }}
                              />
                            </Button>
                          </Col>
                          <Col xs={6}>
                            <Button
                              variant="primary"
                              onClick={openBuyNode}
                              className="buton smallBtn"
                            >
                              <small style={{ fontSize: "12px" }}>
                                Buy node
                              </small>{" "}
                              <img
                                src={addImg}
                                style={{ width: "13px", marginLeft: "5px" }}
                              />
                            </Button>
                          </Col>
                        </Row>

                      
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={6} lg={8} xl={9} className="mb-4">
                  {renderOwnedNFTs()}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;
